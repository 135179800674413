const $ = window.jQuery;

$(document).ready(function($) {
    // variable cache
    var $contactSingleForm = $('#ContactSingleForm');

    // Vue app for multi-step multi-dealer contact form (modal)
    var VueContactSingle = Vue.createApp({
        name: "Contact Modal Single",
        data: () => ({
            loading: false,
            dealer_id: 0,
            dealership_name: '',
            currentStep: 1,
            response: {
                status: '',
                message: ''
            },
            recaptcha_el: null,
            recaptcha_response: '',
            is_phone_required: true,
            is_email_required: false,
            preferred_method: 'phone',
        }),
        watch: {
            preferred_method: function (newValue, oldValue) {
                if (newValue && (newValue !== oldValue)) {
                    if (newValue === 'phone') {
                        this.is_email_required = false
                        this.is_phone_required = true
                    }

                    if (newValue === 'email') {
                        this.is_email_required = true
                        this.is_phone_required = false
                    }
                }
            }
        },
        mounted() {
            var that = this;
            grecaptcha.ready(function() {
                $contactSingleForm[0].querySelectorAll('.recaptcha').forEach( (el) => {
                    let id = el.id;
                    try{
                        if( !el.recaptchad ){
                            that.recaptcha_el = grecaptcha.render(id, {
                                sitekey: window.bhph_grecaptcha_sitekey
                            });

                            window.recaptchas[ id ] = that.recaptcha_el;
                            el.recaptchad = 1
                        }
                    }catch(err){
                        console.error(`oops! recaptcha already rendered for div id => '${id}' `);
                    }
                });
            })
            
            // phone input
            $('#ContactSingleForm').find(".phone").mask("(999) 999-9999");
        },
        methods: {
            goToStep1: function() {
                this.currentStep = 1;
            },
            goToStep2: function() {
                this.currentStep = 2;
            },
            reset: function() {
                this.resetForm();
                this.response = {
                    status: '',
                    message: ''
                };
                this.loading = false;
            },
            resetForm: function() {
                $contactSingleForm.trigger('reset');
            },
            setPreferredMethod: function (e) {
                this.preferred_method = e.target.value
            },
            submitForm: function(e) {
                e.preventDefault()

                let self = this

                grecaptcha.ready(function() {
                    grecaptcha.execute(window.bhph_grecaptcha_sitekey, { action: 'submit' }).then(function(token) {
                        self.recaptcha_response = token

                        if (self.recaptcha_response == '') {
                            self.response['status'] = 'error';
                            self.response['message'] = 'ReCaptcha failed.';
                        }
                        else {
                            self.response['status'] = '';
                        }

                        if (self.response.status != 'error') {

                            var $contactSingleForm = $('#ContactSingleForm');
        
                            let data = $contactSingleForm.serialize();
                            data += `&g-recaptcha-response=${encodeURIComponent(self.recaptcha_response)}`;
        
                            $.ajax({
                                url: $contactSingleForm.attr('action') + '.json',
                                data,
                                type: 'POST',
                                dataType : 'json',
                                beforeSend: function() {
                                    self.loading = true;
                                },
                                success: function(response) {
                                    self.response = response;
        
                                    // successful send
                                    if (response.status == 'success') {
                                        self.goToStep2();
                                        self.resetForm();
                                    }
        
                                    self.loading = false;
        
                                    // reset the captcha value
                                    grecaptcha.reset(self.recaptcha_el);
                                    self.recaptcha_response = '';
                                }
                            });
        
                        }
                    })
                })
            }
        }
    }).mount('#contact-modal-single');


    // Trigger Contact Us Modal to appear
    $('[data-toggle-modal="contact-modal-single"], [data-toggle-modal="contact-modal-single"]').click(function() {
        // we need the dealer_id for the form
        if($(this).data('dealer_id')) {
            VueContactSingle.dealer_id = $(this).data('dealer_id');
        }
        
        // we need the dealership name for the display
        if($(this).data('dealer_name')) {
            VueContactSingle.dealership_name = $(this).data('dealer_name');
        }

        // reset the state of the modal
        VueContactSingle.reset();
        VueContactSingle.currentStep = 1;
    });

});
